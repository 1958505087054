$theme-colors: (
  //blue: #007bff,
  //indigo: #6610f2,
  //purple: #6f42c1,
  //pink: #e83e8c,
  //red: #dc3545,
  //orange: #fd7e14,
  //yellow: #ffc107,
  //green: #28a745,
  //teal: #20c997,
  //cyan: #17a2b8,
  //white: #fff,
  //gray: #6c757d,
  //gray-dark: #343a40,
  //#007bff
  primary: #3393d0,
  //secondary: #6c757d,
  //success: #28a745,
  //info: #17a2b8,
  //warning: #ffc107,
  //danger: #dc3545,
  light: #dadada,
  //dark: #343a40
);

@import '~bootstrap/scss/bootstrap.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

:root {
  --hubspot-chat-bottom: 64px;
  --hubspot-chat-display: block;
}

div#hubspot-messages-iframe-container {
  bottom: var(--hubspot-chat-bottom) !important;
  display: var(--hubspot-chat-display) !important;
  z-index: 1500 !important;
}

//noinspection CssUnknownProperty
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  scrollbar-width: thin;
  scrollbar-color: #a3a4a4 transparent;
}

:root {
  --ios-safe-area-top: 0;
  --ios-safe-area-bottom: 0;
}

body {
  font-family: 'Rubik', sans-serif;
  background: #141414;
  color: white;
  width: 100vw;
  height: calc(100vh - var(--ios-safe-area-top));
  overflow-x: hidden;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.display-1,
.display-2,
.display-3,
.display-4 {
  font-family: 'Poppins', sans-serif;
}

@for $i from 1 through 12 {
  $zIndexBackdrop: #{15000 + (5 * $i)};
  $zIndexContent: #{15000 + (5 * $i) + 2};
  .modal-backdrop.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

@for $i from 1 through 12 {
  $zIndexBackdrop: #{16000 + (5 * $i)};
  $zIndexContent: #{16000 + (5 * $i) + 2};
  .tooltip.show:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='tooltip']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

.list-group-item-dark.dark-list-item {
  background-color: #343a40;
  color: white;
}

code {
  background: rgba(0, 0, 0, 0.1);
  padding: 0 2px;
  border-radius: 4px;
}

.modal-content {
  color: #141414;
}

.font-size-14 {
  font-size: 14px;
}

.display-4 {
  @media screen and (max-width: 767px) {
    font-size: 1.75rem;
  }
}

*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #a3a4a4;
  border-radius: 0;
  border: 0 solid transparent;
}

#root {
  display: flex;
  flex-direction: column;
  margin-top: var(--ios-safe-area-top);
  height: calc(100vh - var(--ios-safe-area-top));
  overflow-x: hidden;
  //padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

button.close[data-dismiss] {
  display: none;
}

pre {
  background: whitesmoke;
  color: grey;
}

.ptr__children {
  overflow-y: auto !important;
}

.modal.dark-modal {
  .modal-content {
    background: #202020;
    color: whitesmoke;
    .modal-header {
      border-bottom: 0;
      button.close {
        color: white;
      }
    }
    .modal-footer {
      border-top: 0;
    }
  }
}

.modal.translucent-modal {
  .modal-content {
    border: none;
    background: rgba(0, 0, 0, 0.7);
    color: whitesmoke;
    .modal-header {
      border-bottom: 0;
      button.close {
        color: white;
      }
    }
    .modal-footer {
      border-top: 0;
    }
  }
}

.list-group.transparent-list {
  .list-group-item {
    background-color: transparent;
    border: none;
  }
}

// https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.list-group.transparent-list {
  &.clickable {
    .list-group-item {
      cursor: pointer;
      color: #d2d1d1;
      background: transparent;
      transition: all ease-in-out 0.2s;
      &:first-child,
      &:last-child {
        border-radius: 0;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
      &.active {
        color: white;
      }
    }
  }
}

.video-lock-icon {
  color: #f9c108;
  opacity: 0.8;
  position: absolute;
  z-index: 12;
  left: 50%;
  top: 50%;
  filter: drop-shadow(0 0 2px black);
  transform: translate(-50%, -50%) scale(1);
  transition: all ease-in-out 0.2s;
}

.dropdown-menu.show {
  opacity: 1 !important;
  pointer-events: auto !important;
}

.text-primary-bright {
  color: #00b4ff;
}

.product-detail-modal {
  div.modal-dialog {
    max-width: 800px !important;
  }
}
