.gaming-level-progress {
  position: absolute;
  height: 4px;
  background: linear-gradient(to right, #39a2b8 var(--level-progress), #343a40 var(--level-progress));
  left: 0;
  right: 0;
  bottom: 0;
}

.exp-drop {
  position: fixed;
  right: 1rem;
  top: 64px;
  font-size: 0.8em;
  transform: translateY(0);
  opacity: 0;
  animation: drop 1s ease-in-out forwards 0.2s;
}

@keyframes drop {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(30px);
    opacity: 0;
  }
}
