.scrollable-card {
  background-size: cover;
  background-position: center;
  display: inline-block;
  position: relative;
  border-radius: 0.25rem;
  transform-origin: center center;
  overflow: hidden;
  cursor: pointer;
  &:not(.disable-hover) {
    &:hover {
      .card-video {
        transform: translate(-50%, -50%) scale(1.03);
      }

      .position-number {
        transform: translate(25%, 18%) scale(1.05);
      }

      .border-box {
        opacity: 1;
        transform: scale(1);
        background: rgba(0, 0, 0, 0.7);
      }

      .video-overlay-actions {
        transition-delay: 0.2s;
        opacity: 1;
        transform: scaleY(1);
      }

      .lock-icon {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0;
      }

      .keep-watching-wrapper {
        bottom: 5px;
        z-index: 20;
        left: 5px;
        right: 5px;
        border-radius: 0.25rem;
      }

      .video-card-flag {
        --card-flag-scale: 1.5;
        opacity: 0;
      }
    }
  }
  .card-video {
    transform-origin: center center;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 0.25rem;
    transform: translate(-50%, -50%) scale(1);
    transition: all ease-in-out 0.2s;
    box-shadow: inset 0 0 50px black;
    object-fit: fill;
    overflow: hidden;
    background: #141414;
    img {
      width: 100%;
      height: 100%;
      &.locked {
        filter: saturate(0.1);
      }
    }
    &.loading {
      &:after {
        content: '';
        top: 0;
        transform: translateX(100%);
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 150;
        animation: shine 1s infinite;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(128, 186, 232, 0) 99%, rgba(125, 185, 232, 0) 100%);
      }
    }
  }
  .border-box {
    position: absolute;
    z-index: 20;
    border: 2px solid white;
    border-radius: 0.25rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(1.2);
    opacity: 0;
    transition: all ease-in-out 0.2s;
    pointer-events: all;
    padding: 0.8rem 0.8rem;
    background: rgba(0, 0, 0, 0);
    text-shadow: 2px 2px 4px rgb(0, 0, 0 / 45%);
    font-size: 1.3rem;
    line-height: 1.2em;
  }
  .video-overlay-actions {
    position: absolute;
    bottom: 0.4rem;
    left: 0.4rem;
    right: 0.4rem;
    z-index: 30;
    transition: all ease-in-out 0.1s;
    opacity: 0;
    transform-origin: bottom center;
    transform: scaleY(0);
    &.keep-watching {
      bottom: 1rem;
    }
  }
  .position-number {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: var(--position-number-font-size, 10rem);
    color: #141414;
    font-weight: 800;
    transform-origin: bottom right;
    transition: transform ease-in-out 0.2s;
    transform: translate(25%, 18%) scale(1);
    line-height: 1em;
    text-shadow: -2px -2px 1px gainsboro;
  }
  .volume-override {
    position: absolute;
    right: 8px;
    bottom: 8px;
    transition: all ease-in-out 0.2s;
    transform-origin: right center;
    transform: scaleX(0);
  }
  .keep-watching-wrapper {
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 5px;
    right: 0;
    overflow: hidden;
    transition: all ease-in-out 0.2s;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    &:not(.prevent-expand) {
      &:hover {
        border-radius: 0.25rem;
        left: 0.3rem;
        right: 0.3rem;
        bottom: 0.4rem;
        z-index: 40;
      }
    }
    .keep-watching-progress-bar {
      height: 5px;
      background-color: #3393d0;
      display: block;
    }
  }
  .video-details {
    background: #202020;
    box-shadow: 0 4px 4px -4px #00000080;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    transform: scaleY(0);
    transform-origin: center top;
    overflow: hidden;
    transition: all ease-in-out 0.2s;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 16px;
    .video-actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 1rem;
      margin-bottom: 1rem;
      margin-top: 0.5rem;
    }
    .video-description {
      margin-bottom: 1rem;
    }
  }
}

.video-card-blurhash-wrapper {
  position: absolute !important;
  width: 100% !important;
  pointer-events: none;
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
  &-hidden {
    opacity: 0;
  }
}

//noinspection CssInvalidPseudoSelector
video::-webkit-media-controls-fullscreen-button {
  display: none;
}

.modal.dark-modal-video {
  .modal-body {
    z-index: 500;
    padding-top: 0;
    //margin-top: -2rem;
    h4 {
      text-shadow: 2px 2px 4px rgb(0, 0, 0 / 45%);
    }
  }
  button.close {
    color: white;
  }
  div.video-wrapper {
    position: relative;
    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 250px;
      background: linear-gradient(to bottom, transparent, #202020 90%);
      pointer-events: none;
    }
    .close-btn {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .video-actions {
      position: absolute;
      right: 1rem;
      bottom: 2rem;
      z-index: 1;
    }
  }
}

.video-author {
  display: flex;
  align-items: center;
  white-space: nowrap;
  .author-photo {
    margin-right: 0.5rem;
    img {
      width: 30px;
      border-radius: 50%;
    }
  }
  .author-data {
    line-height: 1rem;
    .author-name {
      font-size: 1.2rem;
    }
    .author-description {
      color: lightgrey;
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  .tag {
    background: hsl(210deg, 29%, 29%);
    padding: 4px 8px;
    border-radius: 1rem;
    font-size: 0.8rem;
    color: hsl(209deg, 40%, 80%);
    cursor: pointer;
    &.category {
      background: hsl(30deg, 28%, 29%);
      color: hsl(30deg, 39%, 80%);
    }
    &:not(:last-of-type) {
      margin-right: 4px;
    }
  }
}

.video-card-flag {
  position: absolute;
  z-index: 20;
  transform: translate(-20px, -7px) rotate(-45deg) scale(var(--card-flag-scale, 1));
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 16px 30px;
  transition: all ease-in-out 0.2s;
  img {
    position: absolute;
    transform: translate(-51%, -28%) rotate(45deg);
    top: 50%;
    left: 50%;
  }
}

.big-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
  }
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.modal-video-logo {
  width: 100%;
  max-width: 300px;
  margin-top: -7rem;
  pointer-events: none;
  position: relative;
  margin-left: 1rem;
  @media only screen and (max-width: 576px) {
    max-width: Max(300px, calc(100% - 130px));
  }
}

.modal-video-title {
  margin-left: 1rem;
  margin-bottom: 2rem;
  margin-top: -1rem;
  position: relative;
}

.series-select-header {
  min-height: 240px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.4;
    background-image: var(--bg-url);
    background-repeat: no-repeat;
    background-size: cover;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    pointer-events: none;
    background: linear-gradient(to bottom, transparent, #202020 60%);
  }
  .modal-title {
    z-index: 2;
    margin-top: auto;
    text-shadow: 2px 2px 4px rgb(0, 0, 0 / 45%);
  }
}

.badge-pill-only {
  &-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.cockade {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 2rem;
}
