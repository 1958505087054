.bottom-jumbotron {
  background: #141414 !important;
  border-radius: 0 !important;
  margin-top: auto;
}

.pb-8rem {
  padding-bottom: 8rem !important;

  &.jumbotron-pb {
    padding-bottom: 2rem !important;
  }
}
