.animated-legendary-badge-difficulty {
  animation: animated-legendary-badge-difficulty 1s alternate infinite ease-in;
}

@keyframes animated-legendary-badge-difficulty {
  0% {
    color: #389ed9;
  }
  25% {
    color: #2871ab;
  }
  50% {
    color: #e66a1d;
  }
  100% {
    color: #f79c17;
  }
}
