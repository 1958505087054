.billboard-video {
  width: 100%;
  position: relative;
  margin-bottom: -120px;
  .bottom-shader {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 330px;
    background: linear-gradient(to bottom, transparent, #141414 90%);
    z-index: 1;
    pointer-events: none;
  }
  &::before {
    content: '';
    pointer-events: none;
    background: #141414;
    opacity: 0.2;
    box-shadow: inset 0 0 100px #141414;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
  }
  &::after {
    content: '';
    box-shadow: inset 0 0 350px #141414;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    pointer-events: none;
  }
  video {
    width: 100%;
    height: 100%;
    //object-fit: cover;
  }
  .billboard-actions {
    position: absolute;
    bottom: 20vmax;
    right: 1rem;
    z-index: 1;
  }
  .billboard-centered-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
  }
  .billboard-topbar {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    transition: background-color ease-in-out 0.2s;
  }
  .billboard-inset-shadow {
    box-shadow: #141414 0px 40px 40px -10px inset;
  }
  .billboard-topbar-fixed-bg {
    background: #141414b0;
  }
  .billboard-content {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 48px;
    height: calc(90vh - 48px);
    h1,
    p {
      text-shadow: 2px 2px 4px rgb(0, 0, 0 / 45%);
    }
    p {
      font-size: 1.3rem;
    }
  }
}

.blurhash-wrapper {
  position: absolute !important;
  pointer-events: none;
  opacity: 1;
  transition: opacity ease-in-out 0.2s;
  &-hidden {
    opacity: 0;
  }
}

.billboard-mobile-image {
  height: 400px;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: inset 0 -100px 50px -30px #141414;
}

.tag-card {
  background: #343a40;
  border: 3px solid white;
  border-radius: 8px;
  width: 190px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 2px 2px 4px black;
  transform: translateY(0) scale(1);
  transition: all ease-in-out 0.1s;
  &:not(:last-of-type) {
    margin-right: 1.5rem;
  }
  &:hover {
    transform: translateY(-5px) scale(1.05);
    box-shadow: 2px 10px 25px black;
  }
}

.billboard-logo {
  position: absolute;
  width: 50%;
  left: 0;
  bottom: 20vmax;
  transform-origin: bottom left;
  transition: all ease-in-out 0.2s;
  transform: scale(0.5);
  &.big-billboard-logo {
    transform: scale(1);
  }
}

.billboard-loader {
  position: absolute;
  z-index: 123456;
  top: 10vmax;
  left: 50%;
  transform: translateX(-50%);
}

.white-icon {
  filter: brightness(10) grayscale(1);
  height: 1rem;
}
