.native-video-xray {
  padding: 12px 0px;
  min-width: 0;
  width: 0;
  overflow: auto;
  overflow-x: hidden;
  transition: all ease-in-out 0.3s;
  .text-container {
    width: 180px;
  }
  &.xray-visible {
    padding: 12px;
    min-width: 180px;
    width: 180px;
    box-sizing: content-box;
  }
  .xray-element {
    user-select: none;
    &.main-xray-element {
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 0.3rem;
    }
  }
}

.spacer {
  width: 100%;
  margin-left: 8px;
  text-align: left;
}

.plyr__progress__buffer {
  top: 40% !important;
}

#xrayBtnWrapper {
  padding: 8px;
  border-radius: 4px;
  transition: background-color ease-in-out 0.2s;
}

//noinspection CssInvalidFunction
.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(var(--plyr-control-spacing, 26px) * -4));
}

.plyr__controls {
  flex-direction: column;
  .plyr_progress_wrapper {
    width: 100%;
    display: flex;
    .plyr__progress {
      width: 100%;
      margin-right: 24px;
    }
  }
  .play_controls_section {
    display: flex;
    width: 100%;
  }
  .plyr__control {
    box-sizing: border-box;
    border: 3px solid transparent;
    border-radius: 0.3em;
    width: 1.6em;
    height: 1.6em;
    padding: 0;
    font-size: 2.8em;
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    transform-origin: center center;
    transition: all ease-out 0.1s;
    svg {
      height: var(--plyr-control-icon-size, 0.6em);
      width: var(--plyr-control-icon-size, 0.6em);
    }
    &:hover {
      background-color: transparent;
      transform: scale(1.2);
    }
  }
  .plyr__time {
    font-size: 0.9em;
    letter-spacing: 0.027em;
    &.plyr__time--current {
      margin-right: 1em;
    }
  }
}

.xray-element-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  img {
    border-radius: 50%;
    width: 30px;
    margin-right: 8px;
  }
  &.clickable {
    cursor: pointer;
    pointer-events: all;
    color: #67bbf5;
  }
}

div#video-page-content {
  display: flex;
  height: 100%;
  div#main {
    width: 100%;
    height: 100vh;
    background: black;
    position: relative;
    overflow: hidden;
    &.less-higher-overlay {
      height: calc(100vh - 25px);
      .plyr--video {
        height: calc(100vh - 25px);
      }
    }
    video.main-video {
      width: 100%;
      height: 100vh;
    }
    div.pip-video {
      position: absolute;
      top: 1rem;
      right: 1rem;
      box-shadow: 2px 2px 4px black;
      border-radius: 4px;
      cursor: pointer;
      transform-origin: top right;
      transition: all ease-in-out 0.2s;
      transform: scale(1);
      display: flex;
      align-items: flex-start;
      &.native {
        video {
          width: 200px !important;
        }
      }
      &:hover {
        transform: scale(1.05);
        .pip-overlay {
          opacity: 1;
          svg {
            transform: rotate(-40deg);
          }
        }
      }
      video {
        width: 300px;
      }
      .pip-overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in-out 0.2s;
        background: #40404080;
        svg {
          transition: transform ease-out 0.15s;
          transform: rotate(-140deg);
        }
      }
    }
    &.overlay-visible {
      .video-overlay {
        opacity: 1;
        transform: translateY(0);
        box-shadow: inset 0px 100px 70px 0px rgba(0, 0, 0, 0.6);
      }
    }
    .video-overlay {
      opacity: 0;
      transform: translateY(-100vh);
      transition: all ease-in-out 0.4s;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-left: 2rem;
      padding-top: 0.5rem;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: none;
      text-shadow: 2px 2px 4px rgb(0, 0, 0 / 45%);
      display: flex;
      flex-direction: column;
      &.native {
        padding: 0;
      }
      .video-title {
        font-size: 2.5rem;
        padding-left: 3.5rem;
        &.native {
          font-size: 1rem;
          line-height: 3rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: center;
          padding-left: 4rem;
          padding-right: 2rem;
        }
      }
      .video-description {
        max-width: 40vw;
        font-size: 1.25rem;
        &.native {
          display: none;
        }
      }
      .video-author {
        display: flex;
        align-items: center;
        margin-top: 2rem;
        .author-photo {
          margin-right: 1rem;
          img {
            width: 60px;
            border-radius: 50%;
          }
        }
        .author-data {
          .author-name {
            font-size: 1.6rem;
          }
          .author-description {
            color: lightgrey;
          }
        }
      }
      .video-actions {
        pointer-events: all;
        align-self: flex-start;
        margin-top: auto;
        margin-bottom: 80px;
      }
    }

    .video-xray {
      opacity: 0;
      transform: translateX(-100vw);
      position: absolute;
      bottom: 110px;
      left: 1rem;
      transition: all ease-in-out 0.2s;
      width: 300px;
      z-index: 3;
      pointer-events: none;
      &.xray-visible {
        opacity: 1;
        transform: translateX(0);
      }
      .xray-element {
        background: rgba(0, 0, 0, 0.6);
        padding: 4px 8px;
        border-radius: 4px;
        user-select: none;
        font-size: 1rem;
        &.main-xray-element {
          font-weight: 500;
          font-size: 1rem;
          margin-bottom: 0.3rem;
          padding: 8px;
        }
      }
    }
  }
  div.side {
    position: absolute;
    z-index: 3;
    width: 0;
    padding: 0;
    height: 100%;
    right: 0;
    background: #141414cf;
    box-sizing: border-box;
    transition: all ease-out 0.2s;
    overflow-y: auto;
    overflow-x: hidden;
    &.expanded {
      width: calc(300px + 1rem);
      padding: 0.75rem 1rem;
    }
  }
  div.overlay-buttons {
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    position: absolute;
    padding: 0.75rem 1rem;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background: #14141480;
    transition: all ease-out 0.2s;
    display: flex;
    flex-direction: column;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      background: #14141480;
    }
    &.moved {
      opacity: 1;
      right: calc(300px + 1rem);
    }
  }
}

.plyr__controls {
  z-index: 15 !important;
}

//.plyr__video-wrapper::before {
//  position: absolute;
//  bottom: 3rem;
//  right: 2rem;
//  width: 100px;
//  height: 100px;
//  z-index: 10;
//  opacity: 0.4;
//  content: '';
//  background-image: url('watermark.png');
//  background-size: 80px;
//  background-repeat: no-repeat;
//  background-position: bottom right;
//  filter: grayscale(1) brightness(10);
//  pointer-events: none;
//}

div.toast.trial-toast {
  .toast-header button {
    display: none;
  }
  .toast-body {
    color: #141414;
  }
}

.progress-timestamp-marker {
  position: absolute;
  top: 7px;
  width: 3px;
  height: 5px;
  background-color: #00b2fe;
  &.active {
    &::before {
      content: attr(data-tooltip);
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      transform: translateY(-100%);
      white-space: nowrap;
      font-size: 0.7rem;
    }
  }
}

.icon-button {
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &.big-icon {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
    z-index: 1000;
    transform: translateY(-3rem);
    transition: all ease-in-out 0.4s;
    position: absolute;
    margin-left: 2rem;
    svg {
      color: white;
    }
    &.mobile-button {
      @media screen and (orientation: landscape) {
        position: absolute;
        left: 0;
        font-size: 1rem;
        top: 0;
        z-index: 1000;
        transform: translateY(-48px);
        margin-left: 0;
        &.big-icon-visible {
          transform: translateY(0);
        }
      }
    }
    &.big-icon-visible {
      transform: translateY(1rem);
    }
  }
  svg {
    transition: color ease-in-out 0.2s;
  }
}

.timestamp-clickable {
  cursor: pointer;
  margin: 0 -1rem;
  padding: 0.75rem 1rem;
  background: transparent;
  transition: all ease-in-out 0.2s;
  border-left: 6px solid transparent;
  &:hover {
    background: #404040;
  }
  &.active {
    border-left-color: #007bff;
  }
}

.plyr__control:disabled {
  pointer-events: none;
  color: #ffffff66;
}

.mobile-overlay-controls {
  position: absolute;
  z-index: 25000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out 0.2s;
  opacity: 0;
  pointer-events: none;
  &.moc-visible {
    opacity: 1;
    pointer-events: all;
  }
  button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
  }
}

.video-loader {
  position: absolute;
  z-index: 25000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.skip-intro-button {
  position: absolute;
  left: 1rem;
  bottom: 9rem;
}

.video-rating-dialog {
  display: flex;
  align-items: flex-end;
  min-height: calc(100% - 1rem);
  margin-top: 0;
  margin-right: var(--rating-modal-right-margin);
  width: min-content;
  margin-bottom: 0;
  .modal-header {
    justify-content: flex-end;
    .modal-title {
      white-space: nowrap;
      margin-right: 1rem;
    }
    button {
      margin-left: 0;
    }
  }
}
