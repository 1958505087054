.yt-outer-shell {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 90%);
  }

  .yt-inner-shell {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    iframe {
      border: 0;
      height: 100%;
      width: 100%;
    }

    &.static:before {
      content: '';
      position: absolute;
      top: -200px;
      left: -200px;
      right: -200px;
      bottom: -200px;
      background: transparent var(--static-bg-image-url) repeat 0 0;
      opacity: 0.35;
      animation: noise 1s steps(8, end) infinite both;
      --translateX: 0px;
      --translateY: 0px;
      transform: translate(var(--translateX), var(--translateY));
    }
  }
}

.yt-content {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.grid-live {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.thumbnail-video {
  width: 100%;
  aspect-ratio: 16/9;
}

@keyframes noise {
  0% {
    --translateX: 0px;
    --translateY: 0px;
  }
  10% {
    --translateX: -100px;
    --translateY: 100px;
  }
  20% {
    --translateX: 150px;
    --translateY: -100px;
  }
  30% {
    --translateX: -100px;
    --translateY: 100px;
  }
  40% {
    --translateX: 100px;
    --translateY: -150px;
  }
  50% {
    --translateX: -100px;
    --translateY: 200px;
  }
  60% {
    --translateX: -200px;
    --translateY: -100px;
  }
  70% {
    --translateX: 50px;
    --translateY: 100px;
  }
  80% {
    --translateX: 100px;
    --translateY: -150px;
  }
  90% {
    --translateX: 0px;
    --translateY: 200px;
  }
  100% {
    --translateX: -100px;
    --translateY: 100px;
  }
}
