.btn-contact-maso {
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  button {
    border-radius: 999px;
  }
}

.popover#popover-contact-maso {
  max-width: 300px;
}
