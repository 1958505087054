.container-ebooks {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(5, 1fr);
  column-gap: 9%;
  row-gap: 5.15%;
  padding: 5.4% 12%;
  @media only screen and (min-width: 992px) {
    column-gap: 10%;
    row-gap: 6.8%;
    padding: 6.8% 10%;
  }

  .ebook-cover {
    --brightness: 1;
    width: 100%;
    height: 0;
    padding-bottom: 155%;
    position: relative;
    cursor: pointer;
    filter: brightness(var(--brightness)) drop-shadow(3px -1px 2px #00000060);

    &.book-loading {
      --brightness: 0.5;
    }

    .ebook-cover-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      right: -5px;
      border-top-right-radius: 2px;
      border-top: 2px solid var(--border-color);
      border-right: 2px solid var(--border-color);
      left: 0px;
      bottom: 0px;
      background-color: #e7e7e7;
      clip-path: polygon(5% 0, 100% 0, 100% 95%, 95% 100%, 0 100%, 0 4%);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      top: -7px;
      left: 6px;
      width: 6px;
      border-bottom: 2px solid var(--border-color);
      transform: rotate(135deg);
      z-index: 1000;
      transform-origin: bottom left;
      @media only screen and (min-width: 992px) {
        left: 4px;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6%;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 8.2%;
    padding: 4.5% 8%;
  }
}

.cover {
  width: 100%;
  border-top-right-radius: 1px;
}

.top-scaffold {
  background-image: url('./top.jpg');
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  font-size: 0.4rem;
  @media only screen and (min-width: 768px) {
    font-size: 0.85rem;
  }
  @media only screen and (min-width: 992px) {
    width: 80%;
    margin-left: 10%;
    font-size: 1rem;
  }
}

.container-ebooks-wrapper {
  height: 0;
  overflow: hidden;
  padding-bottom: 162%;
  background-image: url('./scaffale-mobile.jpg');
  background-size: 100%;

  @media only screen and (min-width: 992px) {
    width: 80%;
    margin-left: 10%;
    background-image: url('./scaffale.jpg');
    padding-bottom: 89%;
  }
}

.scaffold-wrapper {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ebook-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ebook-lock {
  position: absolute;
  line-height: 0;
  background: #343a40;
  padding: 2px 3px 3px;
  border-radius: 2px;
  right: 0.5rem;
  bottom: 0.5rem;
  @media only screen and (min-width: 992px) {
    right: 0.4rem;
    bottom: 0.45rem;
  }
}

.ebook-zoom {
  position: absolute;
  line-height: 0;
  background: #343a40;
  padding: 2px 3px 3px;
  border-radius: 2px;
  left: 0.5rem;
  bottom: 0.5rem;
  opacity: 0.8;
  transition: opacity ease-out 0.2s;
  @media only screen and (min-width: 992px) {
    left: 0.4rem;
    bottom: 0.45rem;
  }

  &:hover {
    opacity: 1;
  }
}

.book-cover-close {
  position: absolute;
  top: calc(var(--top) * 1px);
  left: calc(var(--left) * 1px);
  z-index: 10001;
  cursor: pointer;
  transform: translate(calc(-100% - 1rem), 1rem);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #00000060;
  font-size: 1.63rem;
  &::after {
    content: '\00D7';
  }
}

.book-cover-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  &-showing {
    opacity: 1;
  }
}

.book-cover-hero {
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 0;
  border-radius: 1px;
  user-select: none;
  transform: translate(calc(var(--left) * 1px), calc(var(--top) * 1px)) scale(1);
  animation: cover-hero-animation cubic-bezier(0.4, 0, 0.2, 1) 0.3s forwards;
  &-leave {
    position: absolute;
    z-index: 10000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(3.5);
    animation: cover-hero-leave cubic-bezier(0.4, 0, 0.2, 1) 0.3s forwards;
  }
}

@keyframes cover-hero-animation {
  to {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(3.5);
  }
}

@keyframes cover-hero-leave {
  to {
    left: 0;
    top: 0;
    transform: translate(calc(var(--left) * 1px), calc(var(--top) * 1px)) scale(1);
  }
}

.banner-library-container {
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 992px) {
    width: 80%;
  }
}
