.level-progress-bar {
  background-color: #474e55 !important;
  border-radius: 999px;
  height: 24px;
  > .progress-bar {
    overflow: visible;
  }
}

.badge-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
}

.login-streak-row {
  display: flex;
  border-radius: 999px;
  overflow: hidden;
  > div {
    flex: 1 1 0;
    width: 0;
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    background-color: #474e55;
    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }
  }
}

.gaming-debug-tools {
  div[role='group'] {
    display: flex !important;
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }
}

.back-to-top-btn {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  svg {
    font-size: 2rem;
    cursor: pointer;
  }
}
