$xs: 0px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

@mixin media-breakpoint-up($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-breakpoint-down($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

.header {
  height: 70vh;
  display: flex;
  flex-direction: column;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--background-image-url);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
    filter: brightness(0.3);
  }
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    filter: brightness(0.3);
    z-index: 1;
    background: linear-gradient(0deg, #090909 20%, transparent);
  }
  .header-content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
    filter: blur(10px);
    transform: scale(1.02);
    transform-origin: top center;
    animation: entrance ease-out 0.8s forwards 0.4s;
    position: relative;
    z-index: 2;
    overflow: 'auto';
    &::-webkit-scrollbar {
      width: 0px;
    }

    .header-content {
      position: relative;
      transform: translateY(20vmin);
      width: 100%;
      .title {
        position: absolute;
        width: 100%;
        top: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        h2 {
          font-size: 1.5rem;
          @include media-breakpoint-up($sm) {
            font-size: 2rem;
          }
          @include media-breakpoint-up($md) {
            font-size: 2.5rem;
          }
          @include media-breakpoint-up($lg) {
            font-size: 3rem;
          }
          @include media-breakpoint-up($xl) {
            font-size: 4rem;
          }
        }

        h3 {
          font-size: 1.5rem;
          @include media-breakpoint-up($sm) {
            font-size: 2rem;
          }
          @include media-breakpoint-up($lg) {
            font-size: 2.5rem;
          }
          @include media-breakpoint-up($xl) {
            font-size: 3rem;
          }
        }

        h4 {
          font-size: 1.1rem;
          @include media-breakpoint-up($md) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}

.new-layout {
  background-color: #030303;
}

@keyframes entrance {
  from {
    opacity: 0;
    filter: blur(10px);
    transform: scale(1.02);
  }
  to {
    opacity: 1;
    filter: blur(0);
    transform: scale(1);
  }
}

$animation-speed: 0.5s;

.fade-in {
  animation: fade-in $animation-speed cubic-bezier(0.25, 0.46, 0.45, 0.94) both 0.2s;
  pointer-events: all;
}
@keyframes fade-in {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.border-thick {
  border-width: 3px !important;
}

.border-radius-large {
  border-radius: 10px;
}

.dark-bg {
  background-color: #202225;
  text-align: start;
}

.light-bg {
  background-color: #272a31;
  text-align: end;
}
