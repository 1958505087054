.tootor-bg {
  background-size: cover;
  background-position: center;
  position: relative;
  background-repeat: no-repeat;
  z-index: -1;
  // Non mi è chiaro se il logo deve essere quello di tootor o della azienda.
  // &::before {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(0, 0, 0, 0.3);
  // }
}

.title-card {
  position: relative;
  z-index: 1;
  background-color: #111d27;
  opacity: 0.95;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;
}
