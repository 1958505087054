section {
  $margin: 2rem;
  margin-left: $margin;
  margin-right: $margin;
  margin-bottom: 3rem;
  .scrollable-h {
    // overflow-x: auto;
    // overflow-y: visible;
    display: grid;
    grid-gap: 24px;
    padding: 0 24px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.slick-dots li button::before {
  color: white !important;
}

.section-header {
  h3 {
    @media screen and (max-width: 576px) {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
}

.slick-arrow {
  height: 100% !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 4px !important;
  width: auto !important;
  background: rgba(0, 0, 0, 0.2) !important;
  min-width: 25px;
  z-index: 10;
  border-radius: 4px;
  &.slick-next::before {
    transition: all ease-in-out 0.2s;
    content: '\276F';
    padding: 4px 8px 4px 4px;
  }
  &.slick-prev::before {
    transition: all ease-in-out 0.2s;
    content: '\276E';
    padding: 4px 4px 4px 5px;
  }
}
