.trial-bar-custom {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 2px !important;
  font-size: 14px;

  &.bottom-56px {
    bottom: calc(56px + var(--ios-safe-area-bottom));
  }
}
