.acty-landing-card {
  cursor: pointer;
  transition: background-color ease-in-out 0.2s;
  text-align: center;
  font-size: 1.5rem;
  height: 7rem;
  justify-content: center;
  padding: 1rem;
  margin: 1rem;
  &:hover {
    background: #4a525a !important;
  }
}

.banner-acty-container {
  width: 100%;
  margin: 0 auto;
}

.one-to-one-box {
  background: #343a40;
  border: 3px solid white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 2px 2px 4px black;
  transform: translateY(0) scale(1);
  transition: all ease-in-out 0.1s;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  height: 8rem;
  display: flex;
  &.category-box {
    &:hover {
      filter: brightness(1.5);
    }
  }
  &:hover {
    transform: translateY(-5px) scale(1.01);
    box-shadow: 2px 10px 25px black;
  }

  .course-image-wrapper {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: rgba(0, 0, 0, 0.5);
      transition: all ease-in-out 0.1s;
    }
    img.course-header {
      filter: brightness(0.5);
      height: inherit;
    }
  }
}
