.feedback-fixed-tab {
  --translation: 8px;
  --opacity: 0.7;
  position: fixed;
  bottom: 6rem;
  right: 0;
  cursor: pointer;
  transition: all ease-out 0.1s;
  transform: translateX(var(--translation));
  box-shadow: rgba(60, 64, 67, 0.3) -1px 0px 2px 0px, rgba(60, 64, 67, 0.15) -2px 0px 6px 2px;
  writing-mode: vertical-lr;
  opacity: var(--opacity);
  span {
    transform: rotate(180deg);
  }
  &:hover {
    --translation: 0;
    --opacity: 1;
  }
}

.gaming-box {
  background-color: #141414;
  border-color: #343a40 !important;
  overflow: hidden;
}
