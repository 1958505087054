.user-data-container {
  overflow: hidden;
  .user-data-content {
    > div {
      display: flex;
      align-items: center;

      > strong {
        margin-right: 4px;
      }
    }

    > * {
      padding: 8px 8px 8px 12px;
      min-height: 47px;

      &:nth-child(odd) {
        background-color: #343a40;
      }
    }
  }
}

.auto-margin-bottom {
  > .btn {
    margin-bottom: 8px;
    white-space: nowrap;
  }
}
