button.google-login {
  all: unset;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: none;
  padding: 0.3rem 0.8rem;
  box-sizing: border-box;
  cursor: pointer;
  transition: all ease-in-out 0.15s;
  background: white;
  &:hover {
    box-shadow: 0 5px 3px -3px rgba(0, 0, 0, 0.1), 0 5px 5px 1px rgba(0, 0, 0, 0.07), 0 3px 8px 2px rgba(0, 0, 0, 0.06);
  }
}
