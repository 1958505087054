.bottom-bar-container {
  background-color: black;
  height: calc(56px + var(--ios-safe-area-bottom));
  display: flex;
  justify-content: space-evenly;

  &.higher-bottom-bar {
    height: calc(81px + var(--ios-safe-area-bottom));
  }

  a {
    flex: 1;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }
}

.btn-primary.bottom-navbar-button {
  background-color: transparent;
  color: #dadada;
  border: 0;
  box-shadow: none;

  &.active {
    color: #3590ce !important;
    box-shadow: none;
  }

  &:hover,
  &.focus,
  &:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle:focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: #dadada;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
  }
}
