.next-video-container {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 550px;
  background-color: #000000e0;
  box-shadow: 0 0 8px 8px #000000e0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  img {
    border-radius: 4px;
  }
  .next-button {
    width: 50%;
    color: black;
    transition: all ease-in-out 0.2s;
  }
}
