.gaming-badge {
  --grayscale: 0.8;
  --brightness: 0.8;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background: linear-gradient(to top, #41484e var(--fill-percentage), transparent var(--fill-percentage));
  border-radius: 16px;
  box-shadow: 0 2px 8px #00000080;
  transition: all ease-out 0.3s;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 16px #000000dc;
  }

  &:last-of-type {
    border-bottom: 0;
  }

  .gaming-badge-image {
    display: flex;
    align-items: flex-start;
    width: 50%;
    margin: 0 auto;
    img {
      width: 100%;
      filter: Grayscale(var(--grayscale)) Brightness(var(--brightness));
    }
  }

  .gaming-badge-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-grow: 1;
    .gaming-badge-title {
      color: #b1b6bb;
      line-height: 1.5rem;
      &-unlocked {
        color: white;
      }
    }
    .gaming-badge-description {
      color: #b1b6bb;
    }
    .level-progress-container {
      margin-top: auto;
    }
  }

  &.unlocked {
    .gaming-badge-content {
      .gaming-badge-description {
        color: #b1b6bb;
      }
    }
    .gaming-badge-image {
      img {
        --grayscale: 0;
        --brightness: 1;
      }
    }
  }
}

.modal-body {
  .gaming-badge {
    padding: 3rem 0 3rem;
  }
}

.badge-progress-bar-container {
  position: relative;
  img.small-badge-image {
    position: absolute;
    transform: scale(2) translateX(-15%);
    height: 1em;
    --grayscale: 0.8;
    --brightness: 0.8;
    filter: Grayscale(var(--grayscale)) Brightness(var(--brightness));
    &-unlocked {
      --grayscale: 0;
      --brightness: 1;
    }
    &#i0 {
      left: 33%;
    }
    &#i1 {
      left: 66%;
    }
    &#i2 {
      left: 99%;
    }
  }
  .badge-progress-bar {
    $height: 1.5em;
    height: $height;
    flex-grow: 1;
    background-color: #474e55 !important;
    border-radius: 999px;
    .progress-bar {
      height: $height;
      //background-color: #bdc5ce !important;
    }
  }
}

.progress-label {
  position: absolute;
  bottom: calc(-100% - 0.5em);
  left: calc(var(--level) * 33% + 16.5%);
  transform: translateX(-50%);
}
