.blog-article {
  cursor: pointer;
  flex-direction: row-reverse;
  &:hover {
    .article-title {
      text-decoration: underline;
    }
    .article-image::after {
      --translation: 50px;
    }
  }
}

.jumbo-article {
  flex-direction: column;
  position: relative;
  font-size: 1.2rem;

  .article-image {
    width: 100%;
    height: 350px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    position: relative;
    margin-left: 0 !important;
    &::after {
      --translation: 25px;
      content: '';
      position: absolute;
      background: linear-gradient(to bottom, transparent, #141414 90%);
      bottom: 0;
      left: 0;
      right: 0;
      transition: transform ease-out 0.2s;
      height: 75%;
      transform: translateY(var(--translation));
    }
  }
  .article-content {
    z-index: 10;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 2rem;
  }

  .article-title {
    font-size: 1.8rem;
  }
}

.article-image {
  width: 450px;
  background-color: #343a41;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
}
