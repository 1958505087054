.course-box {
  background: #343a40;
  border: 3px solid white;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 2px 2px 4px black;
  transform: translateY(0) scale(1);
  transition: all ease-in-out 0.1s;
  padding-top: 0.5rem;
  padding-bottom: 0.7rem;
  margin: 1rem 0;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  &:hover {
    transform: translateY(-5px) scale(1.01);
    box-shadow: 2px 10px 25px black;
  }

  .course-image-wrapper {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.7) 100%);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
    }
    img.course-header {
      filter: brightness(0.5);
      height: inherit;
    }
  }
}

.certification-chip {
  background-color: orangered;
  padding: 0 0.5rem;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  right: 0;
}

.units-length {
  white-space: nowrap;
}

.crop-text-2 {
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.crop-text-3 {
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
