.acty-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 0.5rem;
  &.speakers-list {
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  }
  .acty-categoria-odontoiatrica-card {
    cursor: pointer;
    transition: background-color ease-in-out 0.2s;
    text-align: center;
    font-size: 1.5rem;
    height: 7rem;
    justify-content: center;
    padding: 1rem;
    &:hover {
      background: #4a525a !important;
    }
  }
  .acty-docente-card {
    border-radius: 0.5rem;
    overflow: hidden;
    cursor: pointer;
    .description-docente-card {
      font-size: 1rem;
      padding: 1rem;
      padding-top: 0rem;
    }
    .body-docente-card {
      display: flex;
      .image-full-content {
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
      }
    }
    .disponibilita-card {
      padding: 1rem;
    }
    .chip-container {
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      .chip {
        border: 1px solid rgb(74, 74, 74);
        padding: 0.25rem 0.75rem;
        color: white;
        border-radius: 0.25rem;
        font-size: 0.875rem;
        text-transform: capitalize;
        &.categoria-odonto {
          color: #fff;
          background-color: #3393d0;
          border-color: #3393d0;
        }
      }
    }
  }
}

.title-docente-card {
  font-size: 1.2rem;
  padding: 0.75rem;
  text-align: center;
}

.make-reservation-button {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
}
