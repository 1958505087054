.rating-icon-button {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  &.icon-active {
    &.positive-button {
      background: #2980b940;
    }
    &.negative-button {
      background: #c0392b40;
    }
  }
}

.rating-text {
  font-size: 0.8rem;
}
