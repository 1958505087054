.bottom-bar-suggest-app {
  position: fixed;
  z-index: 99;
  left: 12px;
  right: 12px;
  font-size: 14px;
  bottom: 57px;
  color: white;
  background-color: #2b2b2b;
  border-radius: 6px;
  padding: 4px 8px;
  animation: bottom-up ease-out 0.4s forwards 1s;
  display: flex;
  transform: translateY(200%);
  opacity: 0;
  align-items: center;
  box-shadow: 0 6px 10px black;
}

.custom-download-button {
  margin-left: auto;
  white-space: nowrap;
  font-size: 14px !important;
}
