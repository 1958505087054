.icon-button-wrapper {
  width: 36px;
  height: 36px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  a {
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.light-button {
  &:hover {
    color: #ffffff !important;
  }
}
