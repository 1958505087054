.topbar-button {
  font-size: 0.875rem;
  padding: 0.25rem 0.25rem;
  &:not(.active) {
    border-bottom: 1px solid transparent !important;
    border-radius: 0 !important;
    &:hover {
      background: transparent !important;
      border-bottom-color: transparent !important;
      color: white !important;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  &.active {
    cursor: default;
    pointer-events: none;
    border: 0;
    background-color: transparent !important;
    border-radius: 0;
    border-bottom: 1px solid white !important;
    color: white !important;
  }
}

.advent-calendar-button {
  background-color: #ea2027 !important;
  background-image: linear-gradient(315deg, #ea2027 0%, #ee5a24 74%) !important;
  color: white !important;
  svg {
    transition: color ease-in-out 0.1s;
  }
  &:hover,
  &.advent-calendar-button-active {
    svg {
      color: #27ae60;
    }
  }
}

.btn.btn-outline-light.no-border {
  border: 0;
}

.logo-tootor {
  height: 30px;
}

.tootor-palette-warning.btn-warning {
  background-color: #f6922c;
  border-color: #f6922c;
}

.tootor-app-bar {
  margin-top: var(--ios-safe-area-top);
  .navbar-brand {
    font-size: 0.75rem;
  }
  &-icon-button {
    svg {
      font-size: 1rem;
    }
  }
}
