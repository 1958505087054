.profiling-tags-container-wrapper {
  //grid-area: span 1 / span 2;
  //max-width: calc(3 * 250px + 3 * 1.2rem);
  margin: 0 auto;
  width: 100%;

  .profiling-tags-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(#{'min(300px, 100%)'}, 1fr));
    grid-gap: 1.2rem;

    .profiling-tag {
      height: 150px;
      background: var(--background);
      background-size: cover;
      background-position: center center;
      border-radius: 4px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all ease-in-out 0.2s;
      &::before {
        content: '';
        transition: all ease-in-out 0.2s;
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: rgba(0, 0, 0, 0.5);
        box-shadow: inset 0 0 0 3px white;
      }
      &::after {
        content: attr(data-label);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 2px 2px 4px rgb(0, 0, 0 / 45%);
      }
      &:hover,
      &.active {
        &::before {
          background: rgba(0, 0, 0, 0);
        }
      }
      &.active {
        box-shadow: inset 0 0 0 3px white;
      }
    }
  }
}
