.inside-jumbo-article {
  flex-direction: column;
  position: relative;
  font-size: 1.2rem;

  .inside-article-image {
    width: 100%;
    height: 350px;
    position: relative;
    border-radius: 0;
    margin-left: 0 !important;
    background-color: #343a41;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    &::after {
      content: '';
      position: absolute;
      background: linear-gradient(to bottom, transparent, #141414 90%);
      bottom: 0;
      left: 0;
      right: 0;
      height: 75%;
      transform: translateY(25px);
    }
  }
  .inside-article-content {
    z-index: 10;
    width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 2rem;
  }

  .inside-article-title {
    font-size: 1.8rem;
  }
}
